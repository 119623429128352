<template>
<div class="editor" :style="{ zoom: scale }">
  <div class="trigger-section">
    <StartTriggerBlock :automation="automation" :type="type" ref="startTriggerBlock" />
    <NewActionBlock :index="-1" :class="{ 'has-blocks': automation.blocks.length }" :actionType="actionType" @onActionConfirm="onActionConfirm" @selected="addBlock"/>
  </div>
  <div class="list-blocks">
    <ListBlock :blocks="blocks" level="1" :automation="automation" :actionType="actionType" @onActionClick="onActionClick" @onActionConfirm="onActionConfirm" />
  </div>
</div>
</template>

<script>
import StartTriggerBlock from './StartTriggerBlock'
import NewActionBlock from './NewActionBlock'
import ListBlock from './ListBlock.vue'
import { kStringMaxLength } from 'buffer'

export default {
  components: {
    StartTriggerBlock, NewActionBlock, ListBlock
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'create',
    },

    scale: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      waitContacts: null,
      actionType: undefined,
      selectedBlock: undefined,
      selectedIndex: undefined,
    }
  },

  computed: {
    blocks() {
      let blocks = this.automation.blocks

      blocks = blocks.map(block => {
        if (!block.level) {
          block.level = '1'
        }

        return block
      })

      return blocks
    },
    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },
  },

  mounted() {
    if (!this.$route.params.id && !this.$route.query.templateId) {
      this.$refs.startTriggerBlock.showTriggerModal();
    }
  },


  methods: {
    onActionClick(data) {
      let {type, block, index} = data
      this.actionType = type
      this.selectedBlock = block
      this.selectedIndex = index
    },

    onActionConfirm(data) {
      let {index, level} = data
      if (this.actionType == 'copy')
      {
        this.processBlockAction('copy', index, level)
      }
      if (this.actionType == 'copyAll')
      {
        this.processBlockAction('copy', index, level, true)
      }
      if (this.actionType == 'move')
      {
        this.processBlockAction('move', index, level)
      }
      if (this.actionType == 'moveAll')
      {
        this.processBlockAction('move', index, level, true)
      }
      this.actionType = undefined
    },

    processBlockAction(processType, destIndex, destLevel, isAll = false)
    {
      if (this.selectedBlock.action === 'Condition')
        isAll = true

      let newBlocks = JSON.parse(JSON.stringify([this.selectedBlock]))
      let sourceLevel = this.selectedBlock.level

      let sourceLevelBlocks = this.automation.blocks.filter(b => b.level === sourceLevel)

      if (isAll)
      {
        let newLevelBlocks = sourceLevelBlocks.filter((item, index) => item.level == sourceLevel && index >= this.selectedIndex)
        let childBlocks = this.automation.blocks.filter(item => item.level.startsWith(sourceLevel + '-'))
        newBlocks = JSON.parse(JSON.stringify([...newLevelBlocks, ...childBlocks]))
      }

      newBlocks.forEach(item => {
        item.level = item.level.replace(sourceLevel, destLevel)
      })

      if (processType === 'move')
      {
        if (isAll)
        {
          sourceLevelBlocks = sourceLevelBlocks.slice(0, this.selectedIndex)
          this.automation.blocks = [
            ...this.automation.blocks.filter(item => item.level != sourceLevel && !item.level.startsWith(sourceLevel + '-')),
            ...sourceLevelBlocks
          ]
        } else {
          sourceLevelBlocks.splice(this.selectedIndex, 1)
          this.automation.blocks = [
            ...this.automation.blocks.filter(item => item.level != sourceLevel),
            ...sourceLevelBlocks
          ]
        }
      }
      
      let destLevelBlocks = this.automation.blocks.filter(b => b.level === destLevel)
      
      let prevLevelBlocks = destLevelBlocks.filter((item, index) => item.level == destLevel && index < destIndex)
      let prevChildBlocks = this.automation.blocks.filter(item => item.level != destLevel && !item.level.startsWith(destLevel + '-'))
      let prevBlocks = JSON.parse(JSON.stringify([...prevLevelBlocks, ...prevChildBlocks]))

      let appendLevelBlocks = destLevelBlocks.filter((item, index) => item.level == destLevel && index >= destIndex)
      let appendChildBlocks = this.automation.blocks.filter(item => item.level != destLevel && item.level.startsWith(destLevel + '-'))
      let appendBlocks = JSON.parse(JSON.stringify([...appendLevelBlocks, ...appendChildBlocks]))

      if (appendBlocks.length)
      {
        let newBlockLastLevel = newBlocks[0].level;
        let appendBlockFirstLevel = appendBlocks[0].level;
        newBlocks.forEach(item => {
          if (item.level > newBlockLastLevel)
            newBlockLastLevel = item.level
        });

        appendBlocks.forEach(item => {
          item.level = item.level.replace(appendBlockFirstLevel, newBlockLastLevel)
        })
      }
      
      this.automation.blocks = [
        ...prevBlocks,
        ...newBlocks,
        ...appendBlocks
      ]
    },

    addBlock(args) {
      let newBlock = JSON.parse(JSON.stringify(args.action))
      newBlock.isNew = true

      if(args.action.action === 'CollectContactData') {
        this.contactFields.forEach(function(item) {
          let format = item.type === 3 ? 'mm/dd' : ''
          newBlock.options.collectFields.push({name: item.id, type: item.type, format, label: item.name, enable: false, message: `Please reply with your ${ item.name}`});
        });
      }

      if (args.index === -1) {
        this.automation.blocks = [newBlock, ...this.automation.blocks]
      } else if (args.index < this.automation.blocks.length - 1) {
        this.automation.blocks = [...this.automation.blocks.slice(0, args.index + 1), newBlock, ...this.automation.blocks.slice(args.index + 1, this.automation.blocks.length)]
      } else {
        this.automation.blocks.push(newBlock)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.editor {
  margin: 0px auto 0;
}
svg {
  fill: #5f667e;
  path {
    fill: none;
    stroke: #9298ad;
    stroke-width: 2;
  }
}
.plumbing {
  display: inline-block;
  width: 158px;
  height: 70px;
}

.if-branches {
  display: flex;
  align-items: center;
  justify-content: center;
  .if-yes-branch, .if-no-branch {
    width: 50%;
    justify-content: center;
    display: flex;
  }
  .if-yes {
    border: 2px solid #11866f;
    color: #11866f;
  }
  .if-no {
    border: 2px solid #e40e49;
    color: #e40e49;
  }
  .plumbing {
    position: absolute;
  }
}

.blocknode-path-label {
  position: relative;
  text-align: center;
  margin: 0 0 20px 0;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  background: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
}
</style>